.plans-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background: var(--sunset);
  color: var(--black);
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}

.plan:nth-child(2) {
  transform: scale(1.1);
}

.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.plan > :nth-child(5) {
  font-size: 0.8rem;
  font-weight: bold;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plans > :nth-child(2) > svg {
  fill: white;
}

.plans > :nth-child(2) > button {
  color: var(--orange);
}

@media screen and (max-width: 425px) {
  .plans-container {
    background: white;
    flex-direction: column;
  }
  .programs-header > span {
    height: 1rem;
  }
  .plans {
    flex-direction: column;
    gap: 1rem;
  }
  .plan {
    width: 12rem;
    padding: 1rem 2rem;
    gap: 0.8rem;
    border-radius: 1rem;
  }
  .plan:nth-child(2) {
    transform: scale(1);
  }
}
