.why-us {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.left-wu {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
  padding: 2rem 0 0 0;
}

.left-wu > img {
  object-fit: cover;
}

.left-wu > :nth-child(1) {
  width: 13rem;
  grid-row: 1/3;
  height: auto;
  padding-top: 3rem;
}
.left-wu > :nth-child(2) {
  width: autorem;
  height: 16rem;
  grid-column: 2/4;
}
.left-wu > :nth-child(3) {
  width: 14rem;

  grid-column: 2/3;
}
.left-wu > :nth-child(4) {
  width: 8rem;
  grid-column: 3/4;
  grid-row: 2;
  height: 8rem;
}

.right-wu {
  padding: 5rem 0;
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.right-wu > span {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--purple);
  text-align: start;
}
.right-wu > div {
  display: flex;
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
  text-transform: uppercase;
  padding: 1rem 0;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}

.details > div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

@media screen and (max-width: 425px) {
  .why-us {
    flex-direction: column;
    background: white;
  }
  .left-wu {
    gap: 0.5rem;
    padding-left: 1rem;
  }
  .left-wu > :nth-child(1) {
    width: 7rem;
  }
  .left-wu > :nth-child(2) {
    display: none;
  }
  .left-wu > :nth-child(3) {
    width: 7rem;
    padding: 3rem 0 0 0;
  }
  .left-wu > :nth-child(4) {
    grid-column: 2/3;
    width: 7rem;
  }
  .right-wu > div {
    padding-top: 0;
    flex-direction: column;
  }
}
