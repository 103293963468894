:root {
  --purple: rgba(70, 7, 66, 0.479);
  --primary-color: #5976cc;
  --gold-color: #ce9a23;
  --darkGrey: #717679;
  --grey-color: #d4d1d1;
  --gradient: linear-gradient(
    225deg,
    rgba(56, 73, 163, 0.39) 7.83%,
    rgba(164, 235, 243, 0.432) 87.75%
  );
  --darkBlue: rgba(9, 17, 58, 0.39);
  --blueBackground: linear-gradient(
    90deg,
    rgba(179, 182, 197, 0.5) 3.46%,
    rgba(109, 178, 193, 0.25566) 51.84%,
    rgba(179, 182, 197, 0.5) 100%
  );
  --orange: #da9a23;
  --sunset: linear-gradient(
    73.27deg,
    rgba(247, 175, 126, 0.94) 31.06%,
    rgba(249, 162, 151, 0.876327) 53.13%,
    #fb92b8 90.55%
  );
  --black: #443f38;
}

.App {
  text-align: center;
  background: var(--blueBackground);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: var(--gold-color);
}

.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  border-radius: 8rem;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  cursor: pointer;
  background-color: #9078485e;
}

body {
  font-family: 'inter';
}
