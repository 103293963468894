.Testimonials {
  display: flex;
  gap: 1rem;
  padding: 2rem;
}

.left-t {
  display: flex;
  text-align: start;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: var(--darkBlue);
}

.left-t > :nth-child(1) {
  color: rgba(70, 7, 66, 0.479);
  font-weight: bold;
  font-size: 1.5rem;
}
.left-t > :nth-child(2) {
  font-weight: bold;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
}
.left-t > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
  color: var(--black);
}
.left-t > :nth-child(4) {
  text-transform: none;
  text-align: start;
  letter-spacing: 1px;
  line-height: 40px;
  color: var(--black);
}
.left-t > :nth-child(5) {
  text-transform: none;
  text-align: start;
  letter-spacing: 1px;
  line-height: 40px;
  color: var(--orange);
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}

.right-t > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}

.right-t > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
}

.arrows > img {
  width: 2.5rem;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .Testimonials {
    background: white;
    flex-direction: column;
    gap: 0rem;
    min-height: 90vh;
    padding: 0rem 2rem;
  }
  .left-t {
    gap: 0.5rem;
    justify-content: center;
    text-align: center;
  }
  .left-t :nth-child(1) {
    text-align: start;
  }
  .left-t :nth-child(2),
  .left-t :nth-child(3) {
    font-size: xx-large;
  }
  .left-t :nth-child(4) {
    font-size: larger;
    text-align: center;
    line-height: 1.5rem;
  }
  .left-t :nth-child(5) {
    font-size: medium;
    line-height: 1.5rem;
    justify-content: center;
  }
  .right-t {
    flex-direction: column;
    align-self: center;
  }
  .right-t > :nth-child(1) {
    display: none;
  }
  .right-t > :nth-child(3) {
    display: none;
  }
  .arrows {
    display: flex;
    margin-left: -5.5rem;
  }
}
