.Join {
  display: flex;
  padding: 2rem 2rem 3rem 2rem;
  gap: 10rem;
}

.left-j {
  color: var(--black);
  font-size: 3.75rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  font-family: 'inter', Arial, Helvetica, sans-serif;
}

.left-j > hr {
  position: absolute;
  width: 32.5rem;
  border: 2px solid var(--darkBlue);
  border-radius: 60%;
  margin: -10px 0;
}

.left-j > div {
  display: flex;
  gap: 1rem;
}

.right-j {
  display: flex;
  padding: 2rem 5rem;
}
.right-j > hr {
  position: absolute;
  width: 35rem;
  border: 2px solid var(--darkBlue);
  border-radius: 60%;
  margin: -2.4rem;
}

.email-container {
  display: flex;
  gap: 3rem;
  background: var(--sunset);
  padding: 1rem 2rem;
  border-radius: 0 3rem;
  height: 3rem;
}

.email-container > input {
  background: var(--blueBackground);
  border: none;
  outline: none;
  border-radius: 3rem;
  padding: 0 1.5rem;
  color: var(--black);
}

::placeholder {
  color: var(--purple);
}

.btn-j {
  background: var(--purple);
  width: 7rem;
  color: black;
}

@media screen and (max-width: 425px) {
  .Join {
    flex-direction: column;
    justify-content: center;
    background: white;
    gap: 2rem;
  }
  .left-j > hr {
    width: 15rem;
  }

  .left-j > div {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: xx-large;
    gap: 0;
  }
  .right-j > hr {
    display: none;
  }

  .right-j {
    padding: 1rem 4.5rem;
    width: 6rem;
    justify-content: center;
  }
  .email-container {
    flex-direction: column;
    padding: 0 1.5rem;
    align-items: center;
    gap: 1rem;
    height: 7rem;
    width: 30rem;
  }
  .email-container > input {
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    height: 2rem;
  }
  .btn-j {
    width: 7rem;
    height: 2rem;
  }
}
