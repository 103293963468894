.Yogi {
  display: flex;
  justify-content: space-between;
}

.left-y {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;

  gap: 2rem;
  flex-direction: column;
}

.h1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 3.75rem;
  font-weight: bold;
  color: var(--gold-color);
  justify-content: start;
  font-family: 'inter', 'Arial Narrow', Arial, sans-serif;
}

.h1 > :nth-child(2),
.h1 > :nth-child(3) {
  font-family: Arial, Helvetica, sans-serif;
}

.description {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: start;
  font-size: 1.5rem;
  color: var(--black);
  font-family: 'inter', Arial, Helvetica, sans-serif;
}

.right-y > img {
  width: 100vh;
  height: 90vh;
}

@media screen and (max-width: 425px) {
  .Yogi {
    flex-direction: column;
    background: white;
  }

  .h1 > :nth-child(2),
  .h1 > :nth-child(3) {
    font-size: xx-large;
    align-items: start;
    justify-content: start;
  }
  .description {
    font-size: medium;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .right-y > img {
    width: 18rem;
    height: 18rem;
    margin-top: -3rem;
  }
}
