.teacher-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.teacher-container > img {
  display: flex;
  width: 11rem;
  height: 11rem;
  border-radius: 8rem;
  object-fit: cover;
}

.teacher-container > :nth-child(2) {
  font-size: 2rem;
  color: var(--purple);
  font-weight: bold;
}
.teacher-container > :nth-child(3) {
  display: flex;

  font-size: 1.5rem;
  padding: 1.5rem;
  color: var(--black);
}

.teacher-container > :nth-child(4) {
  font-size: 1.5rem;
  color: var(--gold-color);
  font-weight: bold;
}

.teacher-container > :nth-child(5) {
  display: flex;
  padding-top: 0.5rem;
  font-size: 1.2rem;
  color: var(--darkBlue);
  font-weight: bold;
}
.teacher-container > :nth-child(6) {
  display: flex;

  font-size: 1.2rem;
  color: rgb(68, 63, 56);
  font-weight: bold;
}

@media screen and (max-width: 425px) {
  .teacher-container > :nth-child(3) {
    font-size: medium;
    text-align: center;
    width: 15rem;
  }
  .teacher-container > :nth-child(4) {
    text-align: center;
    width: 13rem;
  }
  .teacher-container > :nth-child(5) {
    text-align: center;
    justify-content: center;
    width: 15rem;
  }
  .teacher-container > :nth-child(6) {
    text-align: center;
    justify-content: center;
    width: 15rem;
  }
}
